import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Link from '@mui/material/Link';
import { MdScreen } from './HomePage.styles';
import Button from '@mui/material/Button';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import { useHistory, useLocation } from 'react-router-dom';
import { getUserType } from '../../utils/user';
import { unRegisterChatServices } from '../../utils/chatUtils';
import { eraseCookie } from '../../utils/cookie';
import GigLogoBlue from '../../assests/image/gigLogoBlue.svg'
import ReactGA from 'react-ga';
import './mainNewPage.css';
import {
  Dialog,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import GigEngineerLogo from '../../assests/GigEngineerLogo.png';
import './HomepageButton.scss';
import { connect } from 'react-redux';
import { InputAdornment } from '@mui/material';
import { Alert } from '@mui/material';
import { Formik, Form } from 'formik';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
require('dotenv').config();
import {
  signInScreenValidationSchema,
  forgotPasswordScreenValidationSchema
} from '../../utils/formikValidations';
import forgotPassword from '../../services/forgotPassword';
import { signUpScreenValidationSchema } from '../../utils/formikValidations';
import { setAuthSignError } from '../../store/actions/auth';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  DialogCss,
  SignInMsg,
  SignUpBusiness,
  SignUpMsg
} from '../SignInForm/SignInForm.styles';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import signUpReq from '../../services/signUp';
import { LoginSocialGoogle } from 'reactjs-social-login';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import ReCAPTCHA from 'react-google-recaptcha';
import { socialLogin, signInReq } from '../../services/signIn';
import { scrollOnTop } from '../../utils/helpers';

ReactGA.initialize(`G-1596JC7760`);
ReactGA.pageview(window.location.pathname + window.location.search);

const pages = [
  { routeName: 'About', routePath: '/#Synergy-Driven' },
  { routeName: 'Why Us?', routePath: '/#Whyus-Wrapper' },
  { routeName: 'Contact', routePath: '/#connected-text' },
  { routeName: 'FAQs', routePath: '/#faq-redirect' },
  // { routeName: 'Features', routePath: '/' },
  { routeName: 'Insights', target: '_blank', routePath: 'https://www.linkedin.com/company/gig-engineer/posts/?feedView=articles&viewAsMember=true' },

];
const action = [
  {
    routeName: 'Engineer Login',
    routePath: '/signup',
    BtnClass: 'BtnSignup',
    variant: 'condensed',
    oncallback: 'findgig'
  },
  {
    routeName: 'Business Login',
    routePath: '/signin',
    BtnClass: 'BtnSignin',
    variant: 'condensed',
    oncallback: 'findengineer'
  }
];
const afterLogin = [
  getUserType() != "admin" ? { routeName: 'My Profile', routePath: 'profile', BtnClass: 'BtnSignup', variant: 'condensed' } : getUserType() == "admin" && { routeName: 'Dashboard', routePath: 'dashboard', BtnClass: 'BtnSignup', variant: 'condensed' },
  { routeName: 'Sign Out', routePath: 'signout', BtnClass: 'BtnSignin', variant: 'condensed' },
];

const Header = (props) => {
  const his = useHistory();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userType, setUserType] = React.useState(getUserType());
  const [open, setOpen] = React.useState(false);
  const [engineerOpen, setEngineerOpen] = React.useState(false);
  const [openSignUp, setOpenSignUp] = React.useState(false);
  const [openSignUpModal, setOpenSignUpModal] = React.useState(false);
  const [openSignUpEngineer, setOpenSignUpEngineer] = React.useState(false);
  const [openSignUpEngineerModal, setOpenSignUpEngineerModal] = React.useState(false);
  const [openForget, setOpenForget] = React.useState(false);
  const [isResetLink, setIsResetLink] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [token, setToken] = React.useState(null);
  const [emailMsg, setEmailMsg] = React.useState('');

  const isValidEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const onChangee = (value) => {
    setToken(value);
  };

  const handleClose = () => {
    setOpen(false);
    setEngineerOpen(false);
    setOpenSignUp(false);
    setOpenSignUpModal(false);
    setOpenSignUpEngineer(false);
    setOpenSignUpEngineerModal(false);
    setOpenForget(false);
    setIsResetLink(false);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [promoted, setPromoted] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    scope: 'r_liteprofile r_emailaddress',
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      linkedInLoginUser({
        "code": code,
        "redirect_uri": `${window.location.origin}/linkedin`
      })
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function HideOnScroll(props) {
    const { children, window } = props;

    let trigger = useScrollTrigger({
      target: window ? window() : undefined
    });

    return (
      <React.Fragment>
        {props.stateForHeader ? (
          <Slide>{children}</Slide>
        ) : (
          <Slide appear={false} direction="down" in={!trigger}>
            {children}
          </Slide>
        )}
      </React.Fragment>
    );
  }

  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func
  };

  const myProfileMethod = (routePath) => {
    if (routePath == 'dashboard') {
      his.push('/admin/engineer/profile');
    }
    else if (routePath == 'profile') {
      if (userType == 'engineer') {
        his.push('/myprofile');
      } else if (userType == 'business') {
        his.push('/representative');
      }
    }
    else {
      unRegisterChatServices();
      if (socket) {
        socket.disconnect();
      }
      eraseCookie('refresh_token');
      eraseCookie('access_token');
      if (location?.pathname?.includes('profile')) {
        his.push(location.pathname);
      } else {
        his.push('/');
      }
    }
    setUserType(getUserType());
  }

  const handleGoogleLogin = (resp, userRole) => {
    const { provider, data } = resp;
    socialLogin({
      displayName: data.name,
      email: data.email,
      photo: data.picture,
      provider: provider,
      userRole: userRole
    });
  };

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <AppBar sx={{ position: 'fixed', boxShadow: 0, backgroundColor: '#F0F6FF' }}>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <div className='toolbar-or-logo'>
              <Box
                sx={{
                  marginLeft: { xs: '-20px', md: 'none' },
                  alignItems: 'center',
                  display: { xs: 'flex', md: 'none' }
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon sx={{ color: '#003FAA' }} />
                </IconButton>

                <Menu
                className='menu-item-container'
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' }
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page.routeName} onClick={handleCloseNavMenu}>
                      <Link
                        underline="none"
                        href={page.routePath}
                        textAlign="center"
                        sx={{ py: '2', px: '2' }}
                      >
                        {page.routeName}
                      </Link>
                    </MenuItem>
                  ))}
                  {action.map((action, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        if (action.oncallback === 'findgig') {
                          setOpen(true);
                        } else if (action.oncallback === 'findengineer') {
                          setEngineerOpen(true);
                        }
                      }}
                    >
                      <Box
                        underline="none"
                        textAlign="center"
                        sx={{ py: '2', px: '2' }}
                        className={action.BtnClass}
                        variant={action.variant}
                      >
                        {action.routeName}
                      </Box>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <MdScreen>
                <img
                  src={GigLogoBlue}
                  width="230px"
                  alt="GIG Engineer"
                  onClick={() => {
                    his.push('/');
                    scrollOnTop();
                  }}
                />
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  {pages.map((page, index, routeName) => (
                    <Link
                      className="MenuLinkCst"
                      href={page.routePath}
                      underline="none"
                      sx={{ py: '10px', px: '10px' }}
                      key={index}
                    >
                      {page.routeName}
                    </Link>
                  ))}
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                    flexGrow: 0,
                    display: { xs: 'none', md: 'flex' }
                  }}
                >
                  {userType
                    ? afterLogin.map((action, index) => (
                      <Button
                        onClick={() => myProfileMethod(action.routePath)}
                        className={action.BtnClass}
                        variant={action.variant}
                        size="small"
                        key={index}
                      >
                        {action.routeName}
                      </Button>
                    ))
                    : action.map((action, index) => (
                      <Button
                        // href={action.routePath}
                        className={action.BtnClass}
                        variant={action.variant}
                        size="small"
                        key={index}
                        onClick={() => {
                          if (action.oncallback === 'findgig') {
                            setOpen(true);
                          } else if (action.oncallback === 'findengineer') {
                            setEngineerOpen(true);
                          }
                        }}
                      >
                        {action.routeName}
                      </Button>
                    ))}
                </Box>
              </MdScreen>
              </div>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Dialog
        sx={{ borderRadius: '10px' }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <div className="dialog-text">
          <Box>
            <img
              sx={{ color: '#0000FF' }}
              src={GigEngineerLogo}
              alt="giglogo"
              fontSize="medium"
              width="200px"
              height="41px"
            />
          </Box>

          <Typography
            sx={{
              textAlign: 'center',
              color: '#5E5E5E',
              fontSize: '16px',
              fontWeight: '500px',
              marginTop: '15px'
            }}
          >
            Sign in as an Engineer
          </Typography>
          <div className="all-button-popup">
            <LoginSocialGoogle
              client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
              onLoginStart={() => {
                console.log('STARTING NOW!!!!!');
              }}
              redirect_uri={'/'}
              scope="openid profile email"
              discoveryDocs="claims_supported"
              access_type="offline"
              onResolve={(resp) => {
                handleGoogleLogin(resp, 'engineer');
              }}
              onReject={(err) => {
                console.error(err);
              }}
            >
              <Button
                className='google-btn'
              >
                <div className='contin-with-google'>
                  <img className='logo-logo' src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Google+Logo.svg" alt="googlelogoicon" fontSize="medium" width="40%" />
                </div>
                <div className='continu-with-google-text'>
                  Continue with Google
                </div>
              </Button>
            </LoginSocialGoogle>
            <Button
              className='google-btn'
              sx={{ marginLeft: '15px' }}
              onClick={linkedInLogin}
            >
              <div className='contin-with-google-link'>
                <img className='logo-logo-linkedin' src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Linkedin+Color.svg" alt="linkdinicon" fontSize="medium" width="40%" />
              </div>
              <div className='continu-with-google-text-link'>
                Continue with Linkedin
              </div>
            </Button>
          </div>
          <p className="text-or">Or</p>
          <Formik
            initialValues={{
              emailId: '',
              password: ''
            }}
            enableReinitialize={true}
            validationSchema={signInScreenValidationSchema}
            onSubmit={(values) => {
              const obj = { ...values };
              signInReq(obj, props.setAuthSignError);
            }}
          >
            {({ values, touched, errors, handleChange, handleBlur, setFieldValue }) => (
              <Form noValidate autoComplete="off">
                <TextField
                  margin="normal"
                  className="textfild-width"
                  id="emailId"
                  name="emailId"
                  label="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.emailId && Boolean(errors.emailId)}
                  helperText={touched.emailId && errors.emailId}
                  value={values.emailId}
                  size="small"
                />
                <TextField
                  margin="normal"
                  className="textfild-width"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  label="Password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  value={values.password}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Button
                  type="submit"
                  className="signin-gig"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, paddingTop: '10px', paddingBottom: '10px' }}
                >
                  SIGN IN
                </Button>
              </Form>
            )}
          </Formik>
          <div className="forget-btn-signin-btn">
            <div
              className="forget-pas-text"
              onClick={() => {
                setOpen(false);
                setOpenForget(true);
              }}
            >
              Forgot password?
            </div>
            <div className="text-sign-1">
              <div className="text-signin-text-1">
                Want to create a new account instead?&nbsp;&nbsp;
              </div>
              <Box
                sx={{
                  fontSize: '11px',
                  color: '#1976d2',
                  fontWeight: '700',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setOpen(false);
                  setTimeout(() => {
                    setOpenSignUp(true);
                  }, 500);
                }}
              >
                Sign up
              </Box>
            </div>
          </div>
          <p className="simpl-text">
            By continuing, you agree to accept our Privacy Policy & Terms of Service.
          </p>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <List sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      onClick={() => {
                        his.push(`/termsconditions`);
                      }}
                    >
                      <Link className="terms-text" href="" underline="hover">
                        Terms and Conditions
                      </Link>
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      onClick={() => {
                        his.push(`/privacypolicy`);
                      }}
                    >
                      <Link className="privacy-text" href="" underline="hover">
                        Privacy Policy
                      </Link>
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
          <p className="copyrigth-text">Copyright © Gig Engineer 2024.</p>
          <br />
        </div>
        <></>
      </Dialog>
      <Dialog
        sx={{ borderRadius: '10px' }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openSignUp}
        onClose={handleClose}
      >
        <div className="signup-dialog-text">
          <Box sx={{ textAlign: 'center' }}>
            <img
              sx={{ color: '#0000FF' }}
              src={GigEngineerLogo}
              alt="giglogo"
              fontSize="medium"
              width="200px"
              height="41px"
            />
          </Box>

          <Typography
            sx={{
              textAlign: 'center',
              color: '#5E5E5E',
              fontSize: '16px',
              fontWeight: '500px',
              marginTop: '10px'
            }}
          >
            Create an account as an Engineer
          </Typography>
          <div className="bnt-signup-engineer-text">
            <LoginSocialGoogle
              client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
              onLoginStart={() => {
                console.log('STARTING NOW!!!!!');
              }}
              redirect_uri={'/'}
              scope="openid profile email"
              discoveryDocs="claims_supported"
              access_type="offline"
              onResolve={(resp) => {
                handleGoogleLogin(resp, 'engineer');
              }}
              onReject={(err) => {
                console.error(err);
              }}
            >
              <Button
                className='google-btn'
              >
                <div className='logo-google-work'>
                  <img className='logo-logo' src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Google+Logo.svg" alt="googlelogoicon" fontSize="medium" width="40%" />
                </div>
                <div className='continu-with-google-text'>
                  Continue with Google
                </div>
              </Button>
            </LoginSocialGoogle>
            <Button
              className='google-btn'
              sx={{ marginLeft: '15px' }}
              onClick={linkedInLogin}
            >
              <div className='logo-link-din-conti'>
                <img className='logo-logo-linkedin' src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Linkedin+Color.svg" alt="linkdinicon" fontSize="medium" width="40%" />
              </div>
              <div className='continu-with-google-text-link'>
                Continue with Linkedin
              </div>
            </Button>
          </div>
          {!_isEmpty(_get(props, 'auth.signError', {})) && (
            <Alert severity="error" className="h-margin-bottom-16">
              {_get(props, 'auth.signError.message', 'some thing went wrong please try again')}
            </Alert>
          )}

          <p className="text-or">Or</p>
          <Formik
            initialValues={{
              fullName: '',
              companyName: '',
              emailId: '',
              password: '',
              signUpAs: '',
              promoOpted: ''
            }}
            enableReinitialize={true}
            validationSchema={signUpScreenValidationSchema}
            onSubmit={async (values) => {
              const obj = { ...values };
              obj['userType'] = 'engineer';
              obj['promoOpted'] = promoted;
              setEmail(obj.emailId);
              if (token) {
                let response = await signUpReq(obj, props.setAuthSignError);
                if (response && response.status === 200);
              }
            }}
          >
            {({ values, touched, errors, handleChange, handleBlur }) => (
              <Form noValidate autoComplete="off">
                <TextField
                  margin="normal"
                  fullWidth
                  id="fullName"
                  name="fullName"
                  label="Full Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.fullName && Boolean(errors.fullName)}
                  helperText={touched.fullName && errors.fullName}
                  value={values.fullName}
                  variant="outlined"
                  size="small"
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="emailId"
                  name="emailId"
                  label="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.emailId && Boolean(errors.emailId)}
                  helperText={touched.emailId && errors.emailId}
                  value={values.emailId}
                  variant="outlined"
                  size="small"
                />
                <TextField
                  margin="normal"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  label="Password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  value={values.password}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <p className="text-1">
                  Do you wish to receive our newsletters and promotional content?
                </p>
                <Box sx={{ marginTop: '-12px' }}>
                  <RadioGroup row aria-label="signUpAs" name="row-radio-buttons-group" size="small">
                    <FormControlLabel
                      value="Yes"
                      control={
                        <Radio
                          onChange={(e) => {
                            setPromoted('yes');
                          }}
                        />
                      }
                      label="Yes"
                      size="small"
                    />
                    <FormControlLabel
                      value="No"
                      control={
                        <Radio
                          onChange={(e) => {
                            setPromoted('no');
                          }}
                        />
                      }
                      label="No"
                      size="small"
                    />
                  </RadioGroup>
                </Box>
                <div className="recapcha-engineer-login">
                  <ReCAPTCHA
                    sitekey="6LcuRXkkAAAAAL3IChCb5HG95esZx8V655kBpwyS"
                    onChange={onChangee}
                  />
                </div>

                <SignUpBusiness>
                  By continuing, you agree to accept our Privacy Policy & Terms of Service.
                </SignUpBusiness>
                <Button
                  type="submit"
                  className="signup-1"
                  fullWidth
                  variant="contained"
                  sx={{ paddingTop: '10px', paddingBottom: '10px' }}
                >
                  SIGN UP
                </Button>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '15px',
                    marginLeft: '2px'
                  }}
                >
                  <SignUpMsg>
                    <Typography
                      sx={{
                        color: '#757575',
                        fontSize: '11px',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        display: 'flex'
                      }}
                    >
                      Already have an account?&nbsp;&nbsp;
                      <Box
                        sx={{
                          fontSize: '11px',
                          color: '#1976d2',
                          fontWeight: '700',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setOpenSignUp(false);
                          setTimeout(() => {
                            setOpen(true);
                          }, 500);
                        }}
                      >
                        Sign in
                      </Box>
                    </Typography>
                  </SignUpMsg>
                </Box>
              </Form>
            )}
          </Formik>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <List sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '11px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      onClick={() => {
                        his.push(`/termsconditions`);
                      }}
                    >
                      <Link className="terms-text" href="" underline="hover">
                        Terms and Conditions
                      </Link>
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '11px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      onClick={() => {
                        his.push(`/privacypolicy`);
                      }}
                    >
                      <Link className="privacy-text" href="" underline="hover">
                        Privacy Policy
                      </Link>
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
          <p className="copyrigth-text-1">Copyright © Gig Engineer 2024.</p>
        </div>
        <></>
      </Dialog>
      <Dialog
        sx={{ borderRadius: '10px' }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openSignUpModal}
        onClose={handleClose}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}
        >
          <Typography sx={{ color: '#008000' }}>
            <VerifiedUserOutlinedIcon fontSize="large" />
          </Typography>
          <br />
          <Typography>
            A verification email has been sent to {email}. Please check your inbox for the mail and
            click the verification link to activate your account. If you did not receive the link,
            please check your spam folders or contact us if you have any queries.
          </Typography>
          <Button
            color="inherit"
            onClick={() => {
              setOpenSignUpModal(false);
              setOpen(true);
            }}
          >
            Click to dismiss
          </Button>
        </Box>
      </Dialog>
      <Dialog
        sx={{ borderRadius: '10px' }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={engineerOpen}
        onClose={handleClose}
      >
        <div className="dialog-text">
          <Box>
            <img
              sx={{ color: '#0000FF' }}
              src={GigEngineerLogo}
              alt="giglogo"
              fontSize="medium"
              width="200px"
              height="41px"
            />
          </Box>

          <Typography
            sx={{
              textAlign: 'center',
              color: '#5E5E5E',
              fontSize: '16px',
              fontWeight: '500px',
              marginTop: '10px'
            }}
          >
            Sign in as a Business
          </Typography>
          <div className='google-button-wrapper-container'>
            <LoginSocialGoogle
              client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
              onLoginStart={() => {
                console.log('STARTING NOW!!!!!');
              }}
              redirect_uri={'/'}
              scope="openid profile email"
              discoveryDocs="claims_supported"
              access_type="offline"
              onResolve={(resp) => {
                handleGoogleLogin(resp, 'business');
              }}
              onReject={(err) => {
                console.error(err);
              }}
            >
              <Button
                className='google-btn-engineer'
              >
                <div className='business-logo-con'>
                  <img className='logo-logo' src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Google+Logo.svg" alt="googlelogoicon" fontSize="medium" width="42%" />
                </div>
                <div className='business-text-logo-work'>
                  Continue with Google Workspace
                </div>
              </Button>
            </LoginSocialGoogle>
          </div>
          <p className="text-or">Or</p>
          <Formik
            initialValues={{
              emailId: '',
              password: ''
            }}
            enableReinitialize={true}
            validationSchema={signInScreenValidationSchema}
            onSubmit={(values) => {
              const obj = { ...values };
              signInReq(obj, props.setAuthSignError);
            }}
          >
            {({ values, touched, errors, handleChange, handleBlur, setFieldValue }) => (
              <Form noValidate autoComplete="off">
                <TextField
                  margin="normal"
                  className="textfild-width"
                  id="emailId"
                  name="emailId"
                  label="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.emailId && Boolean(errors.emailId)}
                  helperText={touched.emailId && errors.emailId}
                  value={values.emailId}
                  size="small"
                />
                <TextField
                  margin="normal"
                  className="textfild-width"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  label="Password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  value={values.password}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Button
                  type="submit"
                  className="signin-gig"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, paddingTop: '10px', paddingBottom: '10px' }}
                >
                  SIGN IN
                </Button>
              </Form>
            )}
          </Formik>
          <div className="forget-btn-signin-btn">
            <div
              className="forget-pas-text"
              onClick={() => {
                setEngineerOpen(false);
                setOpenForget(true);
              }}
            >
              Forgot password?
            </div>
            <div className="text-sign-1">
              <div className="text-signin-text-1">
                Want to create a new account instead?&nbsp;&nbsp;
              </div>
              <Box
                sx={{
                  fontSize: '11px',
                  color: '#1976d2',
                  fontWeight: '700',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setEngineerOpen(false);
                  setTimeout(() => {
                    setOpenSignUpEngineer(true);
                  }, 500);
                }}
              >
                Sign up
              </Box>
            </div>
          </div>
          <p className="simpl-text">
            By continuing, you agree to accept our Privacy Policy & Terms of Service.
          </p>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <List sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      onClick={() => {
                        his.push(`/termsconditions`);
                      }}
                    >
                      <Link className="terms-text" href="" underline="hover">
                        Terms and Conditions
                      </Link>
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      onClick={() => {
                        his.push(`/privacypolicy`);
                      }}
                    >
                      <Link className="privacy-text" href="" underline="hover">
                        Privacy Policy
                      </Link>
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
          <p className="copyrigth-text">Copyright © Gig Engineer 2024.</p>
          <br />
        </div>
      </Dialog>
      <Dialog
        sx={{ borderRadius: '10px' }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openSignUpEngineer}
        onClose={handleClose}
      >
        <div className="signup-dialog-text">
          <Box sx={{ textAlign: 'center' }}>
            <img
              sx={{ color: '#0000FF' }}
              src={GigEngineerLogo}
              alt="giglogo"
              fontSize="medium"
              width="200px"
              height="41px"
            />
          </Box>

          <Typography
            sx={{
              textAlign: 'center',
              color: '#5E5E5E',
              fontSize: '16px',
              fontWeight: '500px',
              marginTop: '10px'
            }}
          >
            Create an account as a Business
          </Typography>
          <LoginSocialGoogle
            client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
            onLoginStart={() => {
              console.log('STARTING NOW!!!!!');
            }}
            redirect_uri={'/'}
            scope="openid profile email"
            discoveryDocs="claims_supported"
            access_type="offline"
            onResolve={(resp) => {
              handleGoogleLogin(resp, 'business');
            }}
            onReject={(err) => {
              console.error(err);
            }}
          >
            <Button
              className='google-btn-engineer'
            >
              <div className='signuplogo-bu'>
                <img className='logo-logo' src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Google+Logo.svg" alt="googlelogoicon" fontSize="medium" width="42%" />
              </div>
              <div className='business-text-logo-work'>
                Continue with Google Workspace
              </div>
            </Button>
          </LoginSocialGoogle>
          {!_isEmpty(_get(props, 'auth.signError', {})) && (
            <Alert severity="error" className="h-margin-bottom-16">
              {_get(props, 'auth.signError.message', 'some thing went wrong please try again')}
            </Alert>
          )}

          <p className="text-or">Or</p>
          <Formik
            initialValues={{
              fullName: '',
              companyName: '',
              emailId: '',
              password: '',
              signUpAs: '',
              promoOpted: ''
            }}
            enableReinitialize={true}
            validationSchema={signUpScreenValidationSchema}
            onSubmit={async (values) => {
              const obj = { ...values };
              obj['userType'] = 'business';
              obj['promoOpted'] = promoted;
              setEmail(obj.emailId);
              if (token) {
                let response = await signUpReq(obj, props.setAuthSignError);
                if (response && response.status === 200);
              }
            }}
          >
            {({ values, touched, errors, handleChange, handleBlur }) => (
              <Form noValidate autoComplete="off">
                <TextField
                  margin="normal"
                  fullWidth
                  id="fullName"
                  name="fullName"
                  label="Full Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.fullName && Boolean(errors.fullName)}
                  helperText={touched.fullName && errors.fullName}
                  value={values.fullName}
                  variant="outlined"
                  size="small"
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="companyName"
                  name="companyName"
                  label="Company Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.companyName && Boolean(errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                  value={values.companyName}
                  variant="outlined"
                  size="small"
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="emailId"
                  name="emailId"
                  label="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.emailId && Boolean(errors.emailId)}
                  helperText={touched.emailId && errors.emailId}
                  value={values.emailId}
                  variant="outlined"
                  size="small"
                />
                <TextField
                  margin="normal"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  label="Password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  value={values.password}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <p className="text-1">
                  Do you wish to receive our newsletters and promotional content?
                </p>
                <Box sx={{ marginTop: '-12px' }}>
                  <RadioGroup row aria-label="signUpAs" name="row-radio-buttons-group" size="small">
                    <FormControlLabel
                      value="Yes"
                      control={
                        <Radio
                          onChange={(e) => {
                            setPromoted('yes');
                          }}
                        />
                      }
                      label="Yes"
                      size="small"
                    />
                    <FormControlLabel
                      value="No"
                      control={
                        <Radio
                          onChange={(e) => {
                            setPromoted('no');
                          }}
                        />
                      }
                      label="No"
                      size="small"
                    />
                  </RadioGroup>
                </Box>
                <div className="recapcha-engineer-login">
                  <ReCAPTCHA
                    sitekey="6LcuRXkkAAAAAL3IChCb5HG95esZx8V655kBpwyS"
                    onChange={onChangee}
                  />
                </div>
                <SignUpBusiness>
                  By continuing, you agree to accept our Privacy Policy & Terms of Service.
                </SignUpBusiness>
                <Button
                  type="submit"
                  className="signup-1"
                  fullWidth
                  variant="contained"
                  sx={{ paddingTop: '10px', paddingBottom: '10px' }}
                >
                  SIGN UP
                </Button>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '15px',
                    marginLeft: '2px'
                  }}
                >
                  <SignUpMsg>
                    <Typography
                      sx={{
                        color: '#757575',
                        fontSize: '11px',
                        fontWeight: '400px',
                        display: 'flex'
                      }}
                    >
                      Already have an account?&nbsp;&nbsp;
                      <Box
                        sx={{
                          fontSize: '11px',
                          color: '#1976d2',
                          fontWeight: '700',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setOpenSignUpEngineer(false);
                          setTimeout(() => {
                            setEngineerOpen(true);
                          }, 500);
                        }}
                      >
                        Sign in
                      </Box>
                    </Typography>
                  </SignUpMsg>
                </Box>
              </Form>
            )}
          </Formik>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <List sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      onClick={() => {
                        his.push(`/termsconditions`);
                      }}
                    >
                      <Link className="terms-text" href="" underline="hover">
                        Terms and Conditions
                      </Link>
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      onClick={() => {
                        his.push(`/privacypolicy`);
                      }}
                    >
                      <Link className="privacy-text" href="" underline="hover">
                        Privacy Policy
                      </Link>
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
          <p className="copyrigth-text-1">Copyright © Gig Engineer 2024.</p>
          <br />
        </div>
        <></>
      </Dialog>
      <Dialog
        sx={{ borderRadius: '10px' }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openSignUpEngineerModal}
        onClose={handleClose}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}
        >
          <Typography sx={{ color: '#008000' }}>
            <VerifiedUserOutlinedIcon fontSize="large" />
          </Typography>
          <br />
          <Typography>
            A verification email has been sent to {email}. Please check your inbox for the mail and
            click the verification link to activate your account. If you did not receive the link,
            please check your spam folders or contact us if you have any queries.
          </Typography>
          <Button
            color="inherit"
            onClick={() => {
              setOpenSignUpEngineer(false);
              setEngineerOpen(true);
            }}
          >
            Click to dismiss
          </Button>
        </Box>
      </Dialog>
      <Dialog
        sx={{ borderRadius: '10px' }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openForget}
        onClose={handleClose}
      >
        <DialogCss>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '40px 30px',
              borderRadius: '10px'
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
                fontSize: '22px',
                fontWeight: '500',
                marginBottom: '30px',
                color: '#1565c0'
              }}
            >
              FORGOT PASSWORD?
            </Box>
            <Box
              sx={{ textAlign: 'center', fontSize: '14px', marginBottom: '20px', color: '#696969' }}
            >
              Enter the email address associated with your account and we’ll send you a link to
              reset your password.
            </Box>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={forgotPasswordScreenValidationSchema}
            >
              {({ values, touched, errors, handleChange, handleBlur }) => (
                <Form
                  noValidate
                  autoComplete="off"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    let data = {
                      emailId: values.email.trim().toLowerCase()
                    };

                    if (values.email === '' || values.email === null) {
                      return setEmailMsg('Email is required!');
                    } else {
                      let isEmailValid = isValidEmail(values.email);
                      if (isEmailValid) {
                        setEmailMsg('');
                      } else {
                        return setEmailMsg('Enter Valid email!');
                      }
                    }
                    let response = await forgotPassword(data, props.setAuthSignError);
                    if (response && response.status === 200) {
                      setIsResetLink(true);
                    } else {
                      setIsResetLink(false);
                    }
                  }}
                >
                  <TextField
                    fullWidth
                    name="email"
                    label="Email Address"
                    onChange={(e) => {
                      setEmailMsg('');
                      handleChange(e);
                    }}
                    value={values.email}
                    variant="outlined"
                    error={emailMsg !== ''}
                    helperText={emailMsg !== '' ? emailMsg : ''}
                  />
                  <Box sx={{ marginTop: '20px' }}>
                    {isResetLink ? (
                      <h4>Link has been sent to your email.</h4>
                    ) : (
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
                      >
                        SEND RESET LINK
                      </Button>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
            <br />
            <SignInMsg>
              Don’t have an account? &nbsp;
              <Link
                underline="hover"
                color="#FF8008"
                className="h-color-orange"
                onClick={() => {
                  setOpenForget(false);
                  setOpenSignUp(true);
                }}
                sx={{ cursor: 'pointer' }}
              >
                Sign Up
              </Link>
            </SignInMsg>
          </Box>
        </DialogCss>
        <br />
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthSignError: (details) => dispatch(setAuthSignError(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
